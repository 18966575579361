import styles from "./FeedHeader.module.scss"
import { useReferencesContext } from "./ReferencesContext"
import { usePolyglot } from "src/contexts"

/*
 * this component render sthe header that always displays at the top of the activity list
 */
export function FeedHeader() {
  const polyglot = usePolyglot()
  const { listHeaderRef } = useReferencesContext()

  return (
    <div className={styles.wrapper}
      ref={listHeaderRef}
      id="Feed"
    >
      <h2 className={styles.container}>
        {polyglot.t("pages.home.activity_list.title")}
      </h2>
    </div>
  )
}
