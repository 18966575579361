import {
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import { defaultGlucoseActivity } from "./defaultGlucoseActivity"
import {
  useReadableGlucoseUnit,
  useGetGlucoseValue,
  useSetGlucoseValue,
  useGlucoseInputProps,
} from "src/hooks"
import styles from "src/styles/entryModal.module.scss"
import type { GlucoseActivity } from "src/types"
import { roundForStep } from "src/utils"

const inputProps = {
  min: 0,
  max: 400,
  step: 1,
}

interface Props {
  activity: GlucoseActivity;
  updateActivity: (a: GlucoseActivity) => void;
}

const id = "glucose-range-input"
const glucoseRangeDetailId = "number-range-input"

export function GlucoseRangeInput(props: Props) {
  const {
    activity,
    updateActivity,
  } = props

  const glucoseUnit = useReadableGlucoseUnit()
  const getGlucoseValue = useGetGlucoseValue()
  const setGlucoseValue = useSetGlucoseValue()

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newValue = setGlucoseValue(Number(e.target.value))

      updateActivity({
        mg_dl: newValue,
      })
    },
    [updateActivity],
  )

  const sharedProps = useGlucoseInputProps(inputProps)

  const value = roundForStep(
    getGlucoseValue(activity.mg_dl),
    sharedProps.step,
  )

  const unset = activity === defaultGlucoseActivity

  return (
    <div className={styles.row}>
      <SliderValue sizePreset="large">
        <SliderValueInput
          {...sharedProps}
          onChange={onChange}
          value={unset ? "" : value}
          placeholder="---"
          id={glucoseRangeDetailId}
        />
        <SliderValueLabelContainer>
          <SliderValueLabel htmlFor={id}>
            {glucoseUnit}
          </SliderValueLabel>
        </SliderValueLabelContainer>
      </SliderValue>
      <Slider className={styles.rangeInputSlider}>
        <SliderInput
          {...sharedProps}
          id={id}
          value={value}
          onChange={onChange}
        />
        <SliderTracks
          min={sharedProps.min}
          max={sharedProps.max}
        />
      </Slider>
    </div>
  )
}
