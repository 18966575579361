import {
  CaretLeftIcon,
  IconButton,
  IconButtonLink,
  IconButtonLinkIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import { Link } from "@tanstack/react-router"
import type {
  ReactNode,
  MouseEventHandler,
} from "react"
import styles from "./OnboardingPageHeader.module.scss"
import type { RoutePath } from "src/types"

interface Props {
  backRoutePath?: RoutePath;
  onBackClick?: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
}
const rangesTitle = "ranges"
export function OnboardingPageHeader(props: Props) {
  const {
    backRoutePath,
    onBackClick,
  } = props

  return (
    <div className={styles.container}>
      <IconButtonLink
        className={styles.backButton}
        stylePreset="withoutBackground"
        asChild
      >
        {onBackClick
          ? (
            <IconButton onClick={onBackClick}>
              <CaretLeftIcon className={styles.backButtonIcon }/>
            </IconButton>
          )
          : backRoutePath
            ? (
              <Link
                to={backRoutePath}
                params={{}}
              >
                <IconButtonLinkIcon>
                  <CaretLeftIcon className={styles.backButtonIcon }/>
                </IconButtonLinkIcon>
              </Link>
            )
            : null
        }
      </IconButtonLink>
      <div className={styles.centerText}>
        <div className={styles.title}
          id={rangesTitle}
        >
          {props.children}
        </div>
      </div>
    </div>
  )
}
