import {
  Modal,
  ModalBody,
  ModalButton,
  ModalCloseX,
  ModalContent,
  ModalHeading,
  ModalTrigger,
  TextArea,
  TextAreaContainer,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"
import styles from "./NoteInput.module.scss"
import { PreviousNotes } from "./PreviousNotes"
import { QuickNotes } from "./QuickNotes"
import { usePolyglot } from "src/contexts"
import { useDeviceSettingsProperty } from "src/hooks"
import type {
  SubEntryType,
  EditableSubEntry,
} from "src/types"

const polyglotPrefix = "components.entry_modal."

interface Props<T extends SubEntryType> {
  currentSubEntry: EditableSubEntry<T>;
  updateSubEntry: (s: Partial<EditableSubEntry<T>>) => void;
  required?: boolean;
}

export function NoteInput<T extends SubEntryType>(props: Props<T>) {
  const polyglot = usePolyglot()
  const hideNotesHistory = useDeviceSettingsProperty("hide_notes_history")

  const {
    currentSubEntry,
    updateSubEntry,
    required,
  } = props

  const value = currentSubEntry.note

  const setNote = useCallback(
    (note: string) => {
      updateSubEntry({ note })
    },
    [
      currentSubEntry,
      updateSubEntry,
    ],
  )

  const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      setNote(e.target.value)
    },
    [setNote],
  )

  const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setNote(e.target.value)
    },
    [setNote],
  )

  return (
    <Modal>
      <ModalTrigger asChild>
        <TextAreaContainer className={styles.trigger}>
          <TextArea
            className={styles.textArea}
            value={value ?? undefined}
            placeholder={polyglot.t(`${polyglotPrefix}note.trigger_placeholder`)}
            required={required}
            readOnly
            id={"note-dialog-trigger"}
          />
        </TextAreaContainer>
      </ModalTrigger>
      <ModalContent id="note-dialog">
        <ModalHeading>
          {polyglot.t(`${polyglotPrefix}note.modal_heading`)}
          <ModalCloseX />
        </ModalHeading>
        <ModalBody className={styles.modalBody}>
          <TextAreaContainer>
            <TextArea
              value={value ?? undefined}
              onChange={onTextAreaChange}
              placeholder={polyglot.t(`${polyglotPrefix}note.placeholder`)}
              maxLength={2500}
              required
              id={"note-input-area"}
            />
          </TextAreaContainer>
          <QuickNotes
            value={value ?? null}
            onChange={onInputChange}
            activityType={currentSubEntry.subentry_type}
          />
          {!hideNotesHistory && (
            <PreviousNotes
              value={value ?? null}
              onChange={onInputChange}
              activityType={currentSubEntry.subentry_type}
              subentryId={currentSubEntry._id}
            />
          )}
          <ModalButton
            stylePreset="primary"
            className={styles.done}
            id={"note-input-done-button"}
          >
            {polyglot.t(`${polyglotPrefix}done_cta`)}
          </ModalButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
