import {
  InfoButton,
  Label,
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
  useSortedSliderValueInputProps,
} from "@tandemdiabetes/sugarmate-react-components"
import dayjs from "dayjs"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react"

import styles from "./Display.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useGetGlucoseValue,
  useSetGlucoseValue,
  useGlucoseInputProps,
  useReadableGlucoseUnit,
  useDeviceSettingsProperty,
} from "src/hooks"
import type { Settings } from "src/types"
import { roundForStep } from "src/utils"

const polyglotPrefix = "pages.settings.display.graph_range."

const inputProps = {
  min: 0,
  max: 400,
  step: 10,
}

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function GraphRange(props: Props) {
  const {
    updateSettings,
    updatedAt,
  } = props

  const polyglot = usePolyglot()
  const currentChartBottom = useDeviceSettingsProperty("chart_bottom")
  const currentChartTop = useDeviceSettingsProperty("chart_top")
  const editedAtRef = useRef<string>(dayjs().toISOString())

  const readableGluecoseUnit = useReadableGlucoseUnit()
  const getGlucoseValue = useGetGlucoseValue()
  const setGlucoseValue = useSetGlucoseValue()

  const [
    valueOne,
    setValueOne,
  ] = useState<number>(getGlucoseValue(currentChartBottom))

  const [
    valueTwo,
    setValueTwo,
  ] = useState<number>(getGlucoseValue(currentChartTop))

  // when the settings property changes, update the state
  useEffect(
    () => {
      if (!updatedAt || dayjs(updatedAt).isAfter(editedAtRef.current)) {
        setValueOne(getGlucoseValue(currentChartBottom))
        setValueTwo(getGlucoseValue(currentChartTop))
      }
    },
    [
      editedAtRef,
      currentChartBottom,
      currentChartTop,
      updatedAt,
      setValueOne,
      setValueTwo,
      getGlucoseValue,
    ],
  )

  // when value one changes, update the state and trigger a settings update
  const onValueOneChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newValue = Number(e.target.value)
      setValueOne(newValue)

      const chartBottom = Math.min(
        newValue,
        valueTwo,
      )
      const chartTop = Math.max(
        newValue,
        valueTwo,
      )

      updateSettings({
        chart_bottom: setGlucoseValue(chartBottom),
        chart_top: setGlucoseValue(chartTop),
      })

      editedAtRef.current = dayjs().toISOString()
    },
    [
      editedAtRef,
      setValueOne,
      valueTwo,
      updateSettings,
      setGlucoseValue,
    ],
  )

  // when value two changes, update the state and trigger a settings update
  const onValueTwoChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newValue = Number(e.target.value)
      setValueTwo(newValue)

      const chartBottom = Math.min(
        newValue,
        valueOne,
      )
      const chartTop = Math.max(
        newValue,
        valueOne,
      )

      updateSettings({
        chart_bottom: setGlucoseValue(chartBottom),
        chart_top: setGlucoseValue(chartTop),
      })

      editedAtRef.current = dayjs().toISOString()
    },
    [
      editedAtRef,
      setValueTwo,
      valueOne,
      updateSettings,
    ],
  )

  // shared props for all inputs
  const sharedProps = useGlucoseInputProps(inputProps)

  // the unique props for the first slider input
  const inputOneProps = useMemo(
    () => ({
      value: roundForStep(
        valueOne,
        sharedProps.step,
      ),
      onChange: onValueOneChange,
    }),
    [
      valueOne,
      onValueOneChange,
      sharedProps.step,
    ],
  )

  // the unique props for the second slider input
  const inputTwoProps = useMemo(
    () => ({
      value: roundForStep(
        valueTwo,
        sharedProps.step,
      ),
      onChange: onValueTwoChange,
    }),
    [
      valueTwo,
      onValueTwoChange,
      sharedProps.step,
    ],
  )

  const [
    lowInputProps,
    highInputProps,
  ] = useSortedSliderValueInputProps(
    inputOneProps,
    inputTwoProps,
  )

  return (
    <div>
      <Label>
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          <div>
            {polyglot.t(`${polyglotPrefix}description_one`)}
          </div>
          <div>
            {polyglot.t(`${polyglotPrefix}description_two`)}
          </div>
        </InfoButton>
      </Label>
      <div className={styles.inputContainer}>
        <SliderValue
          sizePreset="small"
          alignmentPreset="center"
        >
          <SliderValueInput
            {...sharedProps}
            {...lowInputProps}
            id="chart-bottom-number"
          />
          <SliderValueLabelContainer>
            <SliderValueLabel>
              {readableGluecoseUnit}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
        <Slider className={styles.rangeInputSlider}
          id="graph-range-slider"
        >
          <SliderInput
            {...inputOneProps}
            {...sharedProps}
          />
          <SliderInput
            {...inputTwoProps}
            {...sharedProps}
          />
          <SliderTracks
            min={sharedProps.min}
            max={sharedProps.max}
          />
        </Slider>
        <SliderValue
          sizePreset="small"
          alignmentPreset="center"
        >
          <SliderValueInput
            {...sharedProps}
            {...highInputProps}
            id="chart-top-number"
          />
          <SliderValueLabelContainer>
            <SliderValueLabel>
              {readableGluecoseUnit}
            </SliderValueLabel>
          </SliderValueLabelContainer>
        </SliderValue>
      </div>
    </div>
  )
}
