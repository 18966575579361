import { transformMedicationsResponse } from "./transformMedicationsResponse"
import {
  useRxDBSimpleLoader,
  useLoadRxDBCollection,
} from "src/hooks/rxdb"
import {
  medicationSchema,
  medicationCollectionKey,
} from "src/schema"

export function useLoadMedicationsCollection() {
  const [
    ,
    loader,
    resetLoader,
  ] = useRxDBSimpleLoader(
    "meds",
    "/api/v3/meds",
    transformMedicationsResponse,
  )

  useLoadRxDBCollection(
    medicationSchema,
    medicationCollectionKey,
    loader,
    resetLoader,
  )
}
