import {
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
  Popover,
  PopoverTrigger,
  PopoverMenu,
  PopoverMenuItem,
  PopoverMenuItemSeparator,
} from "@tandemdiabetes/sugarmate-react-components"
import {
  Fragment,
  useCallback,
} from "react"
import type { ChangeEventHandler } from "react"

import { inputPropertiesByUnit } from "./inputPropertiesByUnit"
import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"
import type { LiquidActivity } from "src/types"
import { LiquidUnit } from "src/types"
import {
  liquidMicrolitersToUnit,
  liquidUnitToMicroliters,
} from "src/utils"

const polyglotPrefix = "components.entry_modal.liquid.unit."

interface Props {
  etnits?: string;
  activity: LiquidActivity;
  updateActivity: (a: LiquidActivity) => void;
}

const id = "liquid-units-range-input"

const units: Array<LiquidUnit> = [
  LiquidUnit.Milliliters,
  LiquidUnit.Liters,
  LiquidUnit.FluidOunces,
  LiquidUnit.Cups,
  LiquidUnit.Pints,
]

/*
 * renders a number label and range input for choosing number of units
 */
export function LiquidRangeInput(props: Props) {
  const {
    activity,
    updateActivity,
  } = props

  const polyglot = usePolyglot()

  // save changes to num_units
  const onNumUnitsChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const numUnits = Number(e.target.value)
      const microliters = liquidUnitToMicroliters(
        numUnits,
        activity.units,
      )

      updateActivity({
        ...activity,
        microliters,
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  const unset = activity.microliters === 0

  const updateUnit = useCallback(
    (unit: LiquidUnit) => {
      updateActivity({
        ...activity,
        units: unit,
      })
    },
    [
      activity,
      updateActivity,
    ],
  )

  const {
    step,
    max,
    min,
  } = inputPropertiesByUnit[activity.units]

  const value = liquidMicrolitersToUnit(
    activity.microliters,
    activity.units,
  )

  return (
    <div className={styles.row}>
      <SliderValue sizePreset="largePopover">
        <SliderValueInput
          max={max}
          min={min}
          step={step}
          onChange={onNumUnitsChange}
          value={unset ? "" : value}
          required
          id="liquid-number-range"
        />
        <SliderValueLabelContainer>
          <Popover placement="top-start">
            <PopoverTrigger asChild>
              <SliderValueLabel
                htmlFor={id}
                clickable
              >
                {polyglot.t(`${polyglotPrefix}${activity.units}`)}
              </SliderValueLabel>
            </PopoverTrigger>
            <PopoverMenu aria-label={polyglot.t(`${polyglotPrefix}aria_label`)}>
              {units.map(
                (unit: LiquidUnit, index: number) => {
                  const onClick = () => updateUnit(unit)

                  return (
                    <Fragment key={unit}>
                      {index !== 0 && (
                        <PopoverMenuItemSeparator />
                      )}
                      <PopoverMenuItem
                        onClick={onClick}
                        autoDismiss
                      >
                        {polyglot.t(`${polyglotPrefix}${unit}`)}
                      </PopoverMenuItem>
                    </Fragment>
                  )
                },
              )}
            </PopoverMenu>
          </Popover>
        </SliderValueLabelContainer>
      </SliderValue>
      <Slider className={styles.rangeInputSlider}>
        <SliderInput
          min={min}
          max={max}
          step={step}
          id={id}
          value={value}
          onChange={onNumUnitsChange}
          required
        />
        <SliderTracks
          min={min}
          max={max}
        />
      </Slider>
    </div>
  )
}
