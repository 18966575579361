import {
  ModalButton,
  ModalButtons,
  useToasterContext,
} from "@tandemdiabetes/sugarmate-react-components"
import {
  useCallback,
  useEffect,
  useState,
} from "react"
import { foodToFoodActivity } from "./foodToFoodActivity"
import { usePolyglot } from "src/contexts"
import { useUploadImage } from "src/hooks"
import { UploadImageErrorToast } from "src/toasts"
import type {
  EditableSubEntry,
  FoodSearchServing,
  FoodImageResponse,
  FoodSearchFood,
  ActivityType,
  FoodActivity,
} from "src/types"
import { imageURLToFile } from "src/utils"

interface Props {
  activity: FoodActivity;
  updateSubEntry: (s: Partial<EditableSubEntry<ActivityType.Food>>) => void;
  foodSearchFood: FoodSearchFood;
  foodImageResponse?: FoodImageResponse;
  foodSearchServing: FoodSearchServing;
  numberOfServings: number;
}

const polyglotPrefix = "components.food_search."

/*
 * this component renders the buttons for closing search or selecting the current food
 */
export function FoodSearchButtons(props: Props) {
  const {
    updateSubEntry,
    activity,
    foodSearchFood,
    foodImageResponse,
    foodSearchServing,
    numberOfServings,
  } = props

  const polyglot = usePolyglot()
  const uploadImage = useUploadImage()
  const { showToast } = useToasterContext()

  const [
    imageFile,
    setImageFile,
  ] = useState<File | null>(null)

  useEffect(
    () => {
      setImageFile(null)

      if (!foodImageResponse) {
        return
      }

      void (async () => {
        const file = await imageURLToFile(foodImageResponse.food.image_url)
        if (file) {
          setImageFile(file)
        }
      })()
    },
    [
      setImageFile,
      foodImageResponse,
    ],
  )

  const update = useCallback(
    () => {
      void (async () => {
        let imageData: undefined | Partial<EditableSubEntry<ActivityType.Food>> = undefined

        if (imageFile) {
          try {
            // upload the image if there is one
            const imageResponse = await uploadImage(imageFile)

            imageData = {
              image_id: imageResponse.data.image_id,
              image_url: imageResponse.data.image_url,
              thumbnail: imageResponse.data.thumbnail,
            }
          } catch (e) {
            showToast(
              `upload-image-error-toast-${imageFile.name}`,
              UploadImageErrorToast,
            )
          }
        }

        const newFoodActivity = foodToFoodActivity({
          foodSearchFood,
          foodSearchServing,
          servings: numberOfServings,
          title: activity.title || foodSearchFood.name,
        })

        updateSubEntry({
          ...(imageData ?? {}),
          food: newFoodActivity,
        })
      })()
    },
    [
      activity,
      foodSearchFood,
      foodImageResponse,
      foodSearchServing,
      numberOfServings,
      updateSubEntry,
      uploadImage,
      showToast,
      imageFile,
    ],
  )

  return (
    <ModalButtons>
      <ModalButton stylePreset="secondary">
        {polyglot.t(`${polyglotPrefix}cancel_cta`)}
      </ModalButton>
      <ModalButton onClick={update}
        id="food-search-select-btn"
      >
        {polyglot.t(`${polyglotPrefix}submit_cta`)}
      </ModalButton>
    </ModalButtons>
  )
}
