import { Link } from "@tanstack/react-router"
import classNames from "classnames"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"

import styles from "./AccountSummaryLink.module.scss"
import { CurrentReading } from "./CurrentReading"
import { UserInitial } from "src/components"
import {
  usePolyglot,
  useRecentReadingContext,
} from "src/contexts"
import { useAccountProperty } from "src/hooks"

interface Props extends ComponentPropsWithoutRef<"a"> {
  accountId: number;
  selected?: boolean;
}

// TODO when we support multiple users, link to a user-specific URL
export const AccountSummaryLink = forwardRef<HTMLAnchorElement, Props>(
  function AccountSummaryLink(props: Props, ref: Ref<HTMLAnchorElement>) {
    const {
      selected,
      accountId,
      ...htmlProps
    } = props

    const polyglot = usePolyglot()

    const className = classNames(
      styles.container,
      htmlProps.className,
      selected && styles.selectedUser,
    )

    const userName = useAccountProperty(
      "nickname",
      polyglot.t("components.user_initial.default_user_name"),
    )

    const mostRecentGlucoseReading = useRecentReadingContext(accountId).result[0]

    return (
      <Link
        {...htmlProps}
        className={className}
        to="/home"
        ref={ref}
        id="account-summary-link"
      >
        <UserInitial className={styles.userInitial} />
        <div className={styles.userText}>
          <div className={styles.userName}>
            {userName}
          </div>
          {/*
          <div className={styles.selectedUserText}>
            {polyglot.t("components.navigation_menu.selected_user")}
          </div>
            */}
        </div>
        {mostRecentGlucoseReading && (
          <CurrentReading glucoseUserEvent={mostRecentGlucoseReading} />
        )}
      </Link>
    )
  },
)
