import { IconButton } from "@tandemdiabetes/sugarmate-react-components"
import classNames from "classnames"
import dayjs from "dayjs"
import { useCallback } from "react"
import type { MouseEventHandler } from "react"

import styles from "./CreationOverlay.module.scss"
import { useCreationOverlayContext } from "./useCreationOverlayContext"
import {
  ActivityIcon,
  ActivityName,
  useEntryModalPublicContext,
} from "src/components"
import type { SubEntryType } from "src/types"

interface Props {
  activityType: SubEntryType;
}

export function SubEntryButton(props: Props) {
  const { activityType } = props
  const { openEntryModal } = useEntryModalPublicContext()
  const state = useCreationOverlayContext()

  // TODO optionally pass time
  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      openEntryModal({
        userEntry: {
          started_at: state.creationTime ?? dayjs().toISOString(),
          subentries: [],
        },
        subEntry: {
          subentry_type: activityType,
        },
      })
    },
    [
      state.creationTime,
      activityType,
      openEntryModal,
    ],
  )

  const containerClassName = classNames(
    styles.buttonContainer,
    state.colorScheme === "light"
      ? styles.buttonContainerLight
      : styles.buttonContainerDark,
  )

  const subentryButtonClassName = classNames(
    styles.subentryButton,
    state.colorScheme === "light" && styles.subentryButtonLight,
  )

  return (
    <div className={containerClassName}
      id={`subentry-btn-${activityType}`}
    >
      <IconButton
        data-state={state.open ? "open" : "closed"}
        ref={state.refs.setReference}
        {...state.getReferenceProps({
          onClick,
          className: subentryButtonClassName,
        })}
        id={activityType}
      >
        <ActivityIcon activityType={activityType} />
      </IconButton>
      <ActivityName activityType={activityType} />
    </div>
  )
}
