import {
  IconButton,
  CaretLeftIcon,
  CaretRightIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import { useCallback } from "react"
import styles from "./FoodSearchNavigation.module.scss"
import { FoodSearchNavigationDots } from "./FoodSearchNavigationDots"
import type { FoodSearchResponse } from "src/types"

interface Props {
  searchResults: FoodSearchResponse;
  currentFoodIndex: number;
  setCurrentFoodIndex: (n: number) => void;
}

/*
 * this component renders the navigation bar which allows users to browse the
 * results
 */
export function FoodSearchNavigation(props: Props) {
  const {
    currentFoodIndex,
    searchResults,
    setCurrentFoodIndex,
  } = props

  const previousResult = useCallback(
    () => {
      setCurrentFoodIndex(currentFoodIndex - 1)
    },
    [
      currentFoodIndex,
      setCurrentFoodIndex,
    ],
  )

  const nextResult = useCallback(
    () => {
      setCurrentFoodIndex(currentFoodIndex + 1)
    },
    [
      currentFoodIndex,
      setCurrentFoodIndex,
    ],
  )

  return (
    <div className={styles.container}>
      <IconButton
        onClick={previousResult}
        disabled={currentFoodIndex <= 0}
        stylePreset="withoutBackground"
        id={"food-search-prev-result"}
      >
        <CaretLeftIcon />
      </IconButton>
      <FoodSearchNavigationDots {...props} />
      <IconButton
        onClick={nextResult}
        disabled={currentFoodIndex >= searchResults.foods.length - 1}
        stylePreset="withoutBackground"
        id={"food-search-next-result"}
      >
        <CaretRightIcon />
      </IconButton>
    </div>
  )
}
