import { Link } from "@tanstack/react-router"
import type {
  FunctionComponent,
  SVGProps,
} from "react"
import styles from "./PageLink.module.scss"
import { usePolyglot } from "src/contexts"
import type { RoutePath } from "src/types"

export interface Props {
  routePath: RoutePath;
  polyglotPhraseKey: string;
  Icon: FunctionComponent<SVGProps<SVGElement>>;
  id?:string;
}

export function PageLink(props: Props) {
  const {
    Icon,
    routePath,
    polyglotPhraseKey,
    id,
  } = props

  const polyglot = usePolyglot()

  return (
    <Link
      className={styles.container}
      to={routePath}
      params={{}}
      id={id}
    >
      <Icon className={styles.icon} />
      {polyglot.t(polyglotPhraseKey)}
    </Link>
  )
}
