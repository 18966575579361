import {
  LargeDropdown,
  LargeDropdownCaret,
  LargeDropdownCurrentSelection,
  LargeDropdownOption,
  LargeDropdownSelect,
  ValidationContainer,
  ValidationMessage,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import {
  useCallback,
  useMemo,
  useRef,
} from "react"

import type { Country } from "react-phone-number-input"
import {
  getCountryCallingCode,
  isSupportedCountry,
} from "react-phone-number-input"
import { countries } from "./countries"

import { usePolyglot } from "src/contexts"

export type DefaultCountryCode = ""
export const defaultCountryCode: DefaultCountryCode = ""
export type CountryCode = Country | DefaultCountryCode

interface Props {
  countryCode: CountryCode;
  setCountryCode: (value: CountryCode) => void;
}

interface Option {
  value: CountryCode;
  children: string;
  disabled?: boolean;
}

const id = "country-code-select"
const polyglotPrefix = "pages.sign_up.account_information.country_code."

const countryCodeSelectErrorId = "country-code-select-error"

export function CountryCodeSelect(props: Props) {
  const {
    countryCode,
    setCountryCode,
  } = props

  const polyglot = usePolyglot()
  const selectRef = useRef<HTMLSelectElement>(null)

  const options: Array<Option> = useMemo(
    () => {
      const optionsArray: Array<Option> = [
        // the placeholder option
        {
          value: defaultCountryCode,
          children: polyglot.t(`${polyglotPrefix}placeholder`),
          disabled: true,
        },
      ]

      const countryOptions = (Object.keys(countries)
        .filter(
          (value): boolean => {
            if (!isSupportedCountry(value)) {
              return false
            }

            return !!countries[value]?.phone_supported
          },
        ) as Country[])
        .map(
          (value): Option => ({
            value,
            children: polyglot.t(`country.${value}`),
          }),
        )
        .sort(
          (optionA, optionB) => optionA.children.localeCompare(optionB.children),
        )
        .map(
          (option: Option) => {
            if (!option.value) {
              return option
            }

            const callingCode = getCountryCallingCode(option.value)

            return {
              ...option,
              children: `+${callingCode} ${option.children}`,
            }
          },
        )

      optionsArray.push(...countryOptions)
      return optionsArray
    },
    [polyglot],
  )

  const onChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const newCountryCode = e.target.value as Country & keyof typeof countries
      const country = countries[newCountryCode]

      if (country?.phone_supported) {
        selectRef.current?.setCustomValidity("")
      } else {
        selectRef.current?.setCustomValidity(polyglot.t(`${polyglotPrefix}unsupported_error`))
      }

      setCountryCode(newCountryCode)
    },
    [
      polyglot,
      selectRef,
      setCountryCode,
    ],
  )

  return (
    <ValidationContainer>
      <LargeDropdown>
        <LargeDropdownSelect
          id={id}
          value={countryCode}
          onChange={onChange}
          ref={selectRef}
          autoComplete="country"
          required
        >
          {options.map(
            (selectOption) => (
              <LargeDropdownOption
                key={selectOption.value}
                {...selectOption}
              />
            ),
          )}
        </LargeDropdownSelect>
        <LargeDropdownCurrentSelection />
        <LargeDropdownCaret />
      </LargeDropdown>
      <ValidationMessage
        id={countryCodeSelectErrorId}
        invalidMessage={{
          valueMissing: polyglot.t("validation_message.value_missing"),
          customError: polyglot.t(`${polyglotPrefix}unsupported_error`),
        }}
      />
    </ValidationContainer>
  )
}
