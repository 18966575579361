import {
  Form,
  LargeInput,
  ValidationContainer,
  ValidationMessage,
  LargePhoneNumberInput,
} from "@tandemdiabetes/sugarmate-react-components"
import {
  useCallback,
  useMemo,
  useState,
} from "react"
import type { ChangeEventHandler } from "react"
import { isSupportedCountry } from "react-phone-number-input"

import { countries } from "./countries"
import {
  CountryCodeSelect,
  defaultCountryCode,
} from "./CountryCodeSelect"
import type { CountryCode } from "./CountryCodeSelect"
import type { RegistrationPageProps } from "./RegistrationPageProps"
import styles from "./SignUp.module.scss"

import { usePolyglot } from "src/contexts"

const polyglotPrefix = "pages.sign_up.account_information."
const emailId = "email-address-id"
const phoneId = "phone-number-id"

const emailErrorId = "email-address-error-id"
const phoneErrorId = "phone-number-error-id"

export function AccountInformationPage(props: RegistrationPageProps) {
  const {
    children,
    onSubmit,
    registrationForm,
    updateRegistrationForm,
  } = props

  const polyglot = usePolyglot()

  const initialCountryCode: CountryCode = useMemo(
    () => {
      const currentValue = registrationForm.countryOfResidence

      if (!countries[currentValue]?.phone_supported) {
        return defaultCountryCode
      }

      if (isSupportedCountry(currentValue)) {
        return currentValue
      }

      return defaultCountryCode
    },
    [registrationForm.countryOfResidence],
  )

  const [
    countryCode,
    setCountryCode,
  ] = useState<CountryCode>(initialCountryCode)

  const onEmailChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      updateRegistrationForm({
        emailAddress: e.target.value,
      })
    },
    [updateRegistrationForm],
  )

  const onPhoneChange = useCallback(
    (value?: string) => {
      updateRegistrationForm({
        phoneNumber: value ?? "",
      })
    },
    [updateRegistrationForm],
  )

  return (
    <Form
      className={styles.personalInformationForm}
      onSubmit={onSubmit}
    >
      <div className={styles.heading}>
        {polyglot.t(`${polyglotPrefix}title`)}
      </div>
      <div className={styles.inputs}>
        <ValidationContainer>
          <LargeInput
            id={emailId}
            placeholder={polyglot.t(`${polyglotPrefix}email.placeholder`)}
            className={styles.input}
            type="email"
            value={registrationForm.emailAddress}
            onChange={onEmailChange}
            autoComplete="email"
            required
          />
          <ValidationMessage
            id={emailErrorId}
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
              typeMismatch: polyglot.t("validation_message.email_type_mismatch"),
            }}
          />
        </ValidationContainer>
        <CountryCodeSelect
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
        <ValidationContainer>
          <LargePhoneNumberInput
            id={phoneId}
            country={countryCode}
            placeholder={polyglot.t(`${polyglotPrefix}phone.placeholder`)}
            className={styles.input}
            value={registrationForm.phoneNumber}
            onChange={onPhoneChange}
            autoComplete="tel"
            required
          />
          <ValidationMessage
            id={phoneErrorId}
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
              customError: polyglot.t("validation_message.phone_invalid"),
            }}
          />
        </ValidationContainer>
      </div>
      {children}
    </Form>
  )
}
