import styles from "./About.module.scss"
import { ReactComponent as GermanySVG } from "./germanyRegulatory.svg"
import { ReactComponent as HeadquartersSVG } from "./headquarters.svg"
import { ReactComponent as InfoSVG } from "./info.svg"
import { ReactComponent as SwitzerlandSVG } from "./switzerlandRegulatory.svg"
import { ReactComponent as UnitedKingdomSVG } from "./ukRegulatory.svg"
import { usePolyglot } from "src/contexts"

const polyglotPrefix = "pages.settings.about."

export function About() {
  const polyglot = usePolyglot()

  return (
    <div className={styles.container}>
      <div className={styles.disclaimerContainer}>
        <div>
          {polyglot.t(`${polyglotPrefix}disclaimer_one`)}
          <br />
          <br />
          {polyglot.t(`${polyglotPrefix}disclaimer_two`)}
        </div>
        <a
          className={styles.link}
          href="https://sugarmate.io/tos"
          target="_blank"
          rel="noreferrer"
          id="terms-of-service"
        >
          {polyglot.t(`${polyglotPrefix}terms_of_service_link`)}
        </a>
        <a
          className={styles.link}
          href="https://sugarmate.io/privacy"
          target="_blank"
          rel="noreferrer"
          id="privacy-policy"
        >
          {polyglot.t(`${polyglotPrefix}privacy_policy_link`)}
        </a>
        <a
          className={styles.link}
          href={"/acknowledgements.txt"}
          target="_blank"
          rel="noreferrer"
          id="acknowledgements"
        >
          {polyglot.t(`${polyglotPrefix}acknowledgements_link`)}
        </a>
      </div>
      <hr className={styles.hr} />
      <HeadquartersSVG className={styles.svg} />
      <div>
        <div
          className={styles.sugarmate}
          id="company-address"
        >
          {polyglot.t(`${polyglotPrefix}us_address.recipient`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}us_address.street`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}us_address.city`)}
        </div>
      </div>
      <div className={styles.helpRow}>
        <InfoSVG className={styles.svg} />
        <div>
          {polyglot.t(`${polyglotPrefix}help_url`)}
        </div>
      </div>
      <div>
        <div>
          {polyglot.t(
            `${polyglotPrefix}version.software`,
            { version: process.env.REACT_APP_SOFTWARE_VERSION },
          )}
        </div>
        <div>
          {polyglot.t(
            `${polyglotPrefix}version.udi`,
            { udi: "00850018992619" },
          )}
        </div>
        <div>
          {polyglot.t(
            `${polyglotPrefix}version.catalog`,
            { number: "1008181" },
          )}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}version.initial`)}
        </div>
      </div>
      <GermanySVG className={styles.svg} />
      <div>
        <div>
          {polyglot.t(`${polyglotPrefix}germany_address.recipient`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}germany_address.street`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}germany_address.city`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}germany_address.country`)}
        </div>
      </div>
      <SwitzerlandSVG className={styles.svg} />
      <div>
        <div>
          {polyglot.t(`${polyglotPrefix}switzerland_address.recipient`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}switzerland_address.street`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}switzerland_address.city`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}switzerland_address.country`)}
        </div>
      </div>
      <UnitedKingdomSVG className={styles.svg} />
      <div>
        <div>
          {polyglot.t(`${polyglotPrefix}uk_address.recipient`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}uk_address.street`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}uk_address.city`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}uk_address.postal`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}uk_address.country`)}
        </div>
      </div>
      <div>
        <div className={styles.australianSponsor}>
          {polyglot.t(`${polyglotPrefix}australia_address.name`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}australia_address.recipient`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}australia_address.floor`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}australia_address.building`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}australia_address.street`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}australia_address.postal_and_city`)}
        </div>
        <div>
          {polyglot.t(`${polyglotPrefix}australia_address.country`)}
        </div>
      </div>
    </div>
  )
}
