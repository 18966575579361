import {
  LargeInput,
  ValidationContainer,
  ValidationMessage,
  Dialog,
  DialogButton,
  DialogCloseX,
  DialogContent,
  DialogDescription,
  DialogHeading,
} from "@tandemdiabetes/sugarmate-react-components"
import dayjs from "dayjs"
import {
  useCallback,
  useRef,
  useState,
} from "react"
import type { ChangeEventHandler } from "react"

import type { RegistrationPageProps } from "./RegistrationPageProps"
import styles from "./SignUp.module.scss"

import { usePolyglot } from "src/contexts"

const polyglotPrefix = "pages.sign_up.personal_information.date_of_birth."
const dateOfBirthId = "date-of-birth-id"

const dateOfBirthErrorId = "date-of-birth-error-id"

export function DateOfBirthInput(props: RegistrationPageProps) {
  const {
    registrationForm,
    updateRegistrationForm,
  } = props

  const polyglot = usePolyglot()

  const [
    isDialogOpen,
    setIsDialogOpen,
  ] = useState<boolean>(false)

  const [
    dateOfBirthFocused,
    setDateOfBirthFocused,
  ] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const value = e.target.value
      const latestValidDateOfBirth = dayjs()
        .subtract(
          18,
          "years",
        )
        .startOf("day")

      if (value) {
        const isTooYoung = dayjs(value)
          .startOf("day")
          .isAfter(latestValidDateOfBirth)

        if (isTooYoung) {
          inputRef.current?.setCustomValidity(polyglot.t(`${polyglotPrefix}unsupported_error`))
          setIsDialogOpen(true)
        } else {
          inputRef.current?.setCustomValidity("")
        }
      } else {
        inputRef.current?.setCustomValidity("")
      }

      updateRegistrationForm({
        dateOfBirth: value,
      })
    },
    [
      setIsDialogOpen,
      updateRegistrationForm,
      polyglot,
      inputRef,
    ],
  )

  const onFocus = useCallback(
    () => {
      setDateOfBirthFocused(true)
    },
    [setDateOfBirthFocused],
  )

  const onBlur = useCallback(
    () => {
      setDateOfBirthFocused(false)
    },
    [setDateOfBirthFocused],
  )

  return (
    <>
      <ValidationContainer>
        <LargeInput
          ref={inputRef}
          id={dateOfBirthId}
          placeholder={polyglot.t(`${polyglotPrefix}placeholder`)}
          className={styles.input}
          type={(registrationForm.dateOfBirth || dateOfBirthFocused) ? "date" : "text"}
          value={registrationForm.dateOfBirth}
          onChange={onChange}
          autoComplete="bday"
          required
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <ValidationMessage
          id={dateOfBirthErrorId}
          invalidMessage={{
            valueMissing: polyglot.t("validation_message.value_missing"),
            customError: polyglot.t(`${polyglotPrefix}unsupported_error`),
          }}
        />
      </ValidationContainer>
      <Dialog
        open={isDialogOpen}
        onOpenChange={setIsDialogOpen}
      >
        <DialogContent>
          <DialogHeading>
            {polyglot.t(`${polyglotPrefix}dialog.heading`)}
            <DialogCloseX />
          </DialogHeading>
          <DialogDescription>
            {polyglot.t(`${polyglotPrefix}dialog.description`)}
          </DialogDescription>
          <DialogButton>
            {polyglot.t(`${polyglotPrefix}dialog.confirm_cta`)}
          </DialogButton>
        </DialogContent>
      </Dialog>
    </>
  )
}
