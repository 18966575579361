import {
  ActionSubmit,
  Form,
  LargeInput,
  useToasterContext,
  ValidationContainer,
  ValidationMessage,
} from "@tandemdiabetes/sugarmate-react-components"
import { useNavigate } from "@tanstack/react-router"
import escapeStringRegexp from "escape-string-regexp"
import type {
  ChangeEventHandler,
  FormEventHandler,
} from "react"
import {
  useEffect,
  useCallback,
  useState,
} from "react"
import { useIsMounted } from "usehooks-ts"
import styles from "./ResetPassword.module.scss"
import { useResetPassword } from "./useResetPassword"
import { useValidateToken } from "./useValidateToken"
import { PublicPageHeader } from "src/components"
import { usePolyglot } from "src/contexts"
import {
  ResetPasswordErrorToast,
  ResetPasswordSuccessToast,
} from "src/toasts"
import { Status } from "src/types"

const passwordId = "user-password-input"
const passwordConfirmationId = "user-password-confirmation-input"
const polyglotPrefix = "pages.reset_password."

const passwordErrorId = "user-password-input-error"
const passwordConfirmationErrorId = "user-password-confirmation-input-error"

export function ResetPassword() {
  const polyglot = usePolyglot()
  const { showToast } = useToasterContext()
  const resetPassword = useResetPassword()
  const validateToken = useValidateToken()
  const navigate = useNavigate()
  const isMounted = useIsMounted()

  const [
    formState,
    setFormState,
  ] = useState<{
    password: string;
    passwordConfirmation: string;
  }>({
    password: "",
    passwordConfirmation: "",
  })

  const delayedRedirect = useCallback(
    (path: "/forgot_password" | "/sign_in") => {
      setTimeout(
        () => {
          if (isMounted()) {
            void navigate({ to: path })
          }
        },
        5000,
      )
    },
    [
      isMounted,
      navigate,
    ],
  )

  const checkToken = useCallback(
    async () => {
      try {
        await validateToken()
      } catch (e) {
        showToast(
          "reset-password-error-toast",
          ResetPasswordErrorToast,
        )

        delayedRedirect("/forgot_password")
      }
    },
    [
      showToast,
      validateToken,
      delayedRedirect,
    ],
  )

  useEffect(
    () => {
      void checkToken()
    },
    [checkToken],
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      void (async () => {
        try {
          const response = await resetPassword(formState.password)

          if (response.data.status === Status.Fail) {
            throw new Error("reset password request failed")
          }

          showToast(
            "reset-password-error-toast",
            ResetPasswordSuccessToast,
          )

          delayedRedirect("/sign_in")
        } catch (e) {
          showToast(
            "reset-password-error-toast",
            ResetPasswordErrorToast,
          )

          delayedRedirect("/forgot_password")
        }
      })()
    },
    [
      formState,
      resetPassword,
      showToast,
      delayedRedirect,
    ],
  )

  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        password: e.target.value,
      })
    },
    [
      formState,
      setFormState,
    ],
  )

  const onPasswordConfirmationChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFormState({
        ...formState,
        passwordConfirmation: e.target.value,
      })
    },
    [
      formState,
      setFormState,
      polyglot,
    ],
  )

  // this pattern will match an empty value or a value that exactly matches the password field
  const passwordConfirmationPattern = `^(${escapeStringRegexp(formState.password)})?$`

  return (
    <div className={styles.container}>
      <PublicPageHeader backRoutePath="/">
        {polyglot.t(`${polyglotPrefix}title`)}
      </PublicPageHeader>
      <Form
        className={styles.form}
        onSubmit={onSubmit}
      >
        <ValidationContainer>
          <LargeInput
            id={passwordId}
            placeholder={polyglot.t(`${polyglotPrefix}password.placeholder`)}
            className={styles.input}
            type="password"
            value={formState.password}
            onChange={onPasswordChange}
            required
          />
          <ValidationMessage
            id={passwordErrorId}
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
            }}
          />
        </ValidationContainer>
        <ValidationContainer>
          <LargeInput
            id={passwordConfirmationId}
            placeholder={polyglot.t(`${polyglotPrefix}password_confirmation.placeholder`)}
            className={styles.input}
            type="password"
            value={formState.passwordConfirmation}
            onChange={onPasswordConfirmationChange}
            pattern={passwordConfirmationPattern}
            required
          />
          <ValidationMessage
            id={passwordConfirmationErrorId}
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
              patternMismatch: polyglot.t("validation_message.password_mismatch"),
            }}
          />
        </ValidationContainer>
        <ActionSubmit
          value={polyglot.t(`${polyglotPrefix}submit_cta`)}
          stylePreset="primary"
          className={styles.submit}
        />
      </Form>
    </div>
  )
}
