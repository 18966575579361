import {
  FieldSet,
  Legend,
  InfoButton,
  ThemeButton,
  ThemeButtonIcon,
  ThemeButtonName,
  ThemeButtonThumbnail,
  ThemeButtonThumbnails,
  ThemeButtonThumbnailSeparator,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Display.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useDeviceSettingsProperty,
} from "src/hooks"
import themeStyles from "src/styles/themeClasses.module.scss"
import type {
  Settings,
  UserDeviceSettings,
} from "src/types"
import { Theme } from "src/types"

const polyglotPrefix = "pages.settings.display.themes."

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

const themes = [
  Theme.Sugarmate,
  Theme.Tandem,
  Theme.SeaGreen,
  Theme.SunriseSunset,
  Theme.Titanium,
  Theme.MoonMoss,
  Theme.Bubblegum,
  Theme.StrawberryLemonade,
  Theme.Y2KRevival,
  Theme.Chocolate,
  Theme.Desert,
]

const darkOnlyThemes = [
  Theme.Pacific,
  Theme.Midnight,
  Theme.Cavern,
  Theme.Flex,
]

export function Themes(props: Props) {
  const {
    updatedAt,
    updateSettings,
  } = props

  const polyglot = usePolyglot()
  const currentTheme = useDeviceSettingsProperty("theme")

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => e.target.value as Theme,
    [],
  )

  const onValueChange = useCallback(
    (theme: UserDeviceSettings["theme"]) => {
      updateSettings({
        theme,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentTheme,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  return (
    <FieldSet>
      <Legend id="theme-label">
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          {polyglot.t(`${polyglotPrefix}description`)}
        </InfoButton>
      </Legend>
      <div className={styles.themeButtons}>
        {themes.map(
          (theme) => (
            <div
              className={styles.themeButtonContainer}
              key={theme}
            >
              <ThemeButton
                htmlFor={`${theme}-theme`}
                value={theme}
                name="theme"
                checked={value === theme}
                onChange={onChange}
              >
                <ThemeButtonThumbnails>
                  <ThemeButtonThumbnail className={themeStyles[`${theme}Light`]}>
                    <ThemeButtonIcon appearance="light" />
                  </ThemeButtonThumbnail>
                  <ThemeButtonThumbnail className={themeStyles[`${theme}Dark`]}>
                    <ThemeButtonIcon appearance="dark" />
                  </ThemeButtonThumbnail>
                </ThemeButtonThumbnails>
                <ThemeButtonName>
                  {polyglot.t(`${polyglotPrefix}theme.${theme}`)}
                </ThemeButtonName>
              </ThemeButton>
            </div>
          ),
        )}
        {darkOnlyThemes.map(
          (darkOnlyTheme) => (
            <div
              className={styles.themeButtonContainer}
              key={darkOnlyTheme}
            >
              <ThemeButton
                htmlFor={`${darkOnlyTheme}-theme`}
                value={darkOnlyTheme}
                name="theme"
                checked={value === darkOnlyTheme}
                onChange={onChange}
              >
                <ThemeButtonThumbnails>
                  <ThemeButtonThumbnail className={themeStyles[`${darkOnlyTheme}Light`]}>
                    <ThemeButtonIcon appearance="light" />
                  </ThemeButtonThumbnail >
                  <ThemeButtonThumbnailSeparator className={themeStyles[`${darkOnlyTheme}Dark`]} />
                  <ThemeButtonThumbnail className={themeStyles[`${darkOnlyTheme}Dark`]}>
                    <ThemeButtonIcon appearance="dark" />
                  </ThemeButtonThumbnail>
                </ThemeButtonThumbnails>
                <ThemeButtonName>
                  {polyglot.t(`${polyglotPrefix}theme.${darkOnlyTheme}`)}
                </ThemeButtonName>
              </ThemeButton>
            </div>
          ),
        )}
      </div>
    </FieldSet>
  )
}
