import {
  Dropdown,
  DropdownCaret,
  DropdownCurrentSelection,
  DropdownOption,
  DropdownSelect,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"

import { usePolyglot } from "src/contexts"
import type { ExerciseActivity } from "src/types"
import { ExerciseType } from "src/types"

interface Props {
  activity: ExerciseActivity;
  updateActivity: (a: ExerciseActivity) => void;
}

interface Option {
  value: string;
  phraseKey: string;
}

const selectOptions: Array<Option> = []

for (const exerciseTypeKey in ExerciseType) {
  const exerciseType = ExerciseType[exerciseTypeKey as keyof typeof ExerciseType]

  if (exerciseType !== ExerciseType.None) {
    selectOptions.push({
      value: exerciseType,
      phraseKey: `exercise_type.${exerciseType}`,
    })
  }
}

export function ExerciseTypeSelect(props: Props) {
  const {
    activity,
    updateActivity,
  } = props

  const polyglot = usePolyglot()

  const onExerciseTypeChange: ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      const newValue = e.target.value as ExerciseType

      updateActivity({
        ...activity,
        exercise_type: newValue,
      })
    },
    [
      updateActivity,
      activity,
    ],
  )

  return (
    <Dropdown>
      <DropdownSelect
        value={activity.exercise_type}
        onChange={onExerciseTypeChange}
        id="exercise-dropdown-select"
      >
        <DropdownOption
          value={ExerciseType.None}
          disabled
        >
          {polyglot.t("components.entry_modal.exercise.type.placeholder")}
        </DropdownOption>
        {selectOptions.map(
          (selectOption) => (
            <DropdownOption
              key={selectOption.value}
              value={selectOption.value}
            >
              {polyglot.t(selectOption.phraseKey)}
            </DropdownOption>
          ),
        )}
      </DropdownSelect>
      <DropdownCurrentSelection />
      <DropdownCaret />
    </Dropdown>
  )
}
