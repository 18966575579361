import { FloatingPortal } from "@floating-ui/react"
import {
  FloatingActionButton,
  PlusIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import classNames from "classnames"
import type {
  MouseEventHandler,
  CSSProperties,
} from "react"
import {
  useCallback,
  useMemo,
} from "react"
import styles from "./CreationOverlay.module.scss"
import { useCreationOverlayContext } from "./useCreationOverlayContext"


export function CreateButton() {
  const {
    setOpen,
    open: isOpen,
    status: dataStatus,
    transitionMs,
    colorScheme,
  } = useCreationOverlayContext()

  const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    () => {
      setOpen(!isOpen)
    },
    [
      isOpen,
      setOpen,
    ],
  )

  const style: CSSProperties = useMemo(
    () => ({
      transitionDuration: `${transitionMs}ms`,
    }),
    [transitionMs],
  )

  const className = classNames(
    styles.createButton,
    colorScheme === "light"
      ? styles.createButtonLight
      : styles.createButtonDark,
  )

  return (
    <FloatingPortal>
      <FloatingActionButton
        className={className}
        onClick={onClick}
        data-status={dataStatus}
        style={style}
        id={"create-btn"}
      >
        <PlusIcon
          className={styles.plusIcon}
          style={style}
        />
      </FloatingActionButton>
    </FloatingPortal>
  )
}
