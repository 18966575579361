import {
  Label,
  InfoButton,
  Toggle,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEvent } from "react"
import { useCallback } from "react"

import styles from "./Display.module.scss"
import { usePolyglot } from "src/contexts"
import {
  useInputPropsForValue,
  useDeviceSettingsProperty,
} from "src/hooks"
import type {
  Settings,
  UserDeviceSettings,
} from "src/types"

const polyglotPrefix = "pages.settings.display.show_difference."
const id = "show-difference"

interface Props {
  updatedAt?: string;
  updateSettings: (settings: Partial<Settings>) => void;
}

export function ShowDifference(props: Props) {
  const {
    updatedAt,
    updateSettings,
  } = props

  const polyglot = usePolyglot()
  const currentShowDifference = useDeviceSettingsProperty("show_delta")

  const adapter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => e.target.checked,
    [],
  )

  const onValueChange = useCallback(
    (showDelta: UserDeviceSettings["show_delta"]) => {
      updateSettings({
        show_delta: showDelta,
      })
    },
    [updateSettings],
  )

  const {
    value,
    onChange,
  } = useInputPropsForValue({
    value: currentShowDifference,
    onChange: onValueChange,
    adapter,
    updatedAt,
  })

  return (
    <div className={styles.toggleRow}>
      <Label htmlFor={id}
        id="show-difference-label"
      >
        {polyglot.t(`${polyglotPrefix}label`)}
        <InfoButton title={polyglot.t(`${polyglotPrefix}title`)}>
          <div>
            {polyglot.t(`${polyglotPrefix}description_one`)}
          </div>
          <div>
            {polyglot.t(`${polyglotPrefix}description_two`)}
          </div>
          <div>
            {polyglot.t(`${polyglotPrefix}description_three`)}
          </div>
        </InfoButton>
      </Label>
      <Toggle
        id={"show-difference-toggle"}
        checked={!!value}
        onChange={onChange}
      />
    </div>
  )
}
