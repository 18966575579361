import {
  Form,
  LargeInput,
  ValidationContainer,
  ValidationMessage,
} from "@tandemdiabetes/sugarmate-react-components"
import { useCallback } from "react"
import type { ChangeEventHandler } from "react"

import { CountryOfResidenceSelect } from "./CountryOfResidenceSelect"
import { DateOfBirthInput } from "./DateOfBirthInput"
import type { RegistrationPageProps } from "./RegistrationPageProps"
import styles from "./SignUp.module.scss"

import { usePolyglot } from "src/contexts"

const polyglotPrefix = "pages.sign_up.personal_information."
const firstNameId = "first-name-id"
const lastNameId = "last-name-id"

const firstNameErrorId = "first-name-error-id"
const lastNameErrorId = "last-name-id"

export function PersonalInformationPage(props: RegistrationPageProps) {
  const {
    children,
    onSubmit,
    registrationForm,
    updateRegistrationForm,
  } = props

  const polyglot = usePolyglot()

  const onFirstNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      updateRegistrationForm({
        firstname: e.target.value,
      })
    },
    [updateRegistrationForm],
  )

  const onLastNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      updateRegistrationForm({
        lastname: e.target.value,
      })
    },
    [updateRegistrationForm],
  )

  return (
    <Form
      className={styles.personalInformationForm}
      onSubmit={onSubmit}
    >
      <div className={styles.heading}>
        {polyglot.t(`${polyglotPrefix}title`)}
      </div>
      <div className={styles.inputs}>
        <ValidationContainer>
          <LargeInput
            id={firstNameId}
            placeholder={polyglot.t(`${polyglotPrefix}first_name.placeholder`)}
            className={styles.input}
            type="text"
            value={registrationForm.firstname}
            onChange={onFirstNameChange}
            autoComplete="given-name"
            required
          />
          <ValidationMessage
            id={firstNameErrorId}
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
            }}
          />
        </ValidationContainer>
        <ValidationContainer>
          <LargeInput
            id={lastNameId}
            placeholder={polyglot.t(`${polyglotPrefix}last_name.placeholder`)}
            className={styles.input}
            type="text"
            value={registrationForm.lastname}
            onChange={onLastNameChange}
            autoComplete="family-name"
            required
          />
          <ValidationMessage
            id={lastNameErrorId}
            invalidMessage={{
              valueMissing: polyglot.t("validation_message.value_missing"),
            }}
          />
        </ValidationContainer>
        <DateOfBirthInput {...props} />
        <CountryOfResidenceSelect {...props} />
      </div>
      {children}
    </Form>
  )
}
