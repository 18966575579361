import classNames from "classnames"
import type {
  ComponentPropsWithoutRef,
  Ref,
} from "react"
import { forwardRef } from "react"
import styles from "./FeedItemContent.module.scss"

export type Props = ComponentPropsWithoutRef<"div">

export const FeedItemContentText = forwardRef<HTMLDivElement, Props>(
  function FeedItemContentText(props: Props, ref: Ref<HTMLDivElement>) {
    const className = classNames(
      styles.feedItemContentText,
      props.className,
    )

    return (
      <div id="feed-content"
        {...props}
        className={className}
        ref={ref}
      />
    )
  },
)
