import { useMergeRefs } from "@floating-ui/react"
import dayjs from "dayjs"
import {
  useCallback,
  useMemo,
  useEffect,
} from "react"
import AutoSizer from "react-virtualized-auto-sizer"
import { VariableSizeList } from "react-window"

import { Cell } from "./Cell"
import { useXPercentageAsTime } from "./hooks"
import { Overlay } from "./Overlay"
import { useReferencesContext } from "./ReferencesContext"
import { useScrollPositionContext } from "./ScrollPositionContext"
import { useTooltipContext } from "./TooltipContext"
import styles from "./VirtualList.module.scss"
import { useVirtualListContext } from "./VirtualListContext"
import { useXAxisContext } from "./XAxisContext"
import {
  useDeviceSettingsProperty,
  useSortedNonContinuousItems,
  useDebounceValue,
} from "src/hooks"
import type {
  ReactWindowAutoSizerArgs,
  ReactWindowOnItemsRenderedArgs,
  ReactWindowOnScrollArgs,
} from "src/types"

export interface Props {
  graphStop: string;
  scrollToTime?: string;
  onScrollChange?: (id: string) => void;
}

export function VirtualList(props: Props) {
  const {
    scrollToTime,
    onScrollChange,
    graphStop,
  } = props

  const graphIconScale = useDeviceSettingsProperty("graph_icon_scale")
  const graphSelectorId = "graph-selector"

  // reference and getReferenceProps() must be attached to the tooltip trigger
  const {
    refs,
    getReferenceProps,
  } = useTooltipContext()

  const {
    cellWidth,
    cellWidthRef,
    firstCellEnd,
    graphStart,
    graphStartRef,
    minutesPerCell,
    minutesPerCellRef,
  } = useXAxisContext()

  const {
    setScrollPosition,
    setVisibleIndices,
    scrollPositionRef,
  } = useScrollPositionContext()

  const {
    containerElementRef,
    listElementRef,
  } = useReferencesContext()

  const { containerRef } = useVirtualListContext()

  const numberOfItems = useMemo(
    () => {
      const days = dayjs(graphStart).diff(
        graphStop,
        "day",
        true,
      )

      const graphMinutes = days * 24 * 60
      return Math.ceil(graphMinutes / minutesPerCell)
    },
    [
      minutesPerCell,
      graphStop,
      graphStart,
    ],
  )

  const getItemSize = useCallback(
    (index: number): number => {
      // all cells aside from the first cell are cellWidth in width
      if (index > 0) {
        return cellWidth
      }

      const diff = dayjs(graphStart).diff(
        firstCellEnd,
        "minutes",
        true,
      )

      const size = (diff / minutesPerCell) * cellWidth
      return size
    },
    [
      firstCellEnd,
      cellWidth,
      graphStart,
      minutesPerCell,
    ],
  )

  const getItemKey = useCallback(
    (index: number): string => {
      const firstCellStart = dayjs(firstCellEnd).add(minutesPerCell)
      const cellStart = firstCellStart
        .subtract(
          index * minutesPerCell,
          "minutes",
        )
        .toISOString()
      const cellStop = dayjs(cellStart)
        .subtract(
          minutesPerCell,
          "minutes",
        )
        .toISOString()

      const key = `${cellStop}-${cellStart}`
      return key
    },
    [
      firstCellEnd,
      graphStart,
      minutesPerCell,
    ],
  )

  const updateVisibleIndices = useCallback(
    ({
      visibleStartIndex,
      visibleStopIndex,
    }: ReactWindowOnItemsRenderedArgs) => {
      setVisibleIndices([
        visibleStartIndex,
        visibleStopIndex,
      ])
    },
    [setVisibleIndices],
  )

  const scrollPositionToTime = useCallback(
    (scrollPosition: number) => {
      const pixelsPerMinute = cellWidthRef.current / minutesPerCellRef.current
      return dayjs(graphStartRef.current)
        .subtract(
          scrollPosition / pixelsPerMinute,
          "minutes",
        )
        .toISOString()
    },
    [
      cellWidthRef,
      graphStartRef,
      minutesPerCellRef,
    ],
  )

  const timeToScrollPosition = useCallback(
    (time: string) => {
      const pixelsPerMinute = cellWidthRef.current / minutesPerCellRef.current
      const diffInMinutes = dayjs(graphStartRef.current)
        .diff(
          time,
          "minutes",
        )

      return diffInMinutes * pixelsPerMinute
    },
    [
      cellWidthRef,
      graphStartRef,
      minutesPerCellRef,
    ],
  )

  // every time scrollToTime changes days, scroll to center time
  useEffect(
    () => {
      if (scrollToTime === undefined) {
        return
      }

      if (!listElementRef.current || !containerElementRef.current) {
        return
      }

      if (scrollToTime === "now") {
        listElementRef.current.scrollTo(0)
      } else {
        const offset = containerElementRef.current.getBoundingClientRect().width ?? 0
        listElementRef.current.scrollTo((offset / -2) + timeToScrollPosition(scrollToTime))
      }
    },
    [
      scrollToTime,
      scrollPositionToTime,
      timeToScrollPosition,
      listElementRef,
      containerElementRef,
    ],
  )

  const oldestVisibleTimeOnGraph = useXPercentageAsTime(0)
  const newestVisibleTimeOnGraph = useXPercentageAsTime(100)

  const oldestVisibleTime = useDebounceValue(
    oldestVisibleTimeOnGraph,
    0,
  )

  const newestVisibleTime = useDebounceValue(
    newestVisibleTimeOnGraph,
    0,
  )

  const graphVisibleNonContinuousItems = useSortedNonContinuousItems(
    oldestVisibleTime,
    newestVisibleTime,
    1,
  )

  const latestVisibleNonContinuousItem = graphVisibleNonContinuousItems[0]

  useEffect(
    () => {
      // scroll feed to the first visible item in the graph unless the graph is
      // at the far right
      if (latestVisibleNonContinuousItem && scrollPositionRef.current !== 0) {
        onScrollChange?.(latestVisibleNonContinuousItem.id)
      }
    },
    [
      scrollPositionRef,
      onScrollChange,
      latestVisibleNonContinuousItem,
    ],
  )


  const onScroll = useCallback(
    ({ scrollOffset }: ReactWindowOnScrollArgs) => {
      setScrollPosition(scrollOffset)

      // scroll graph to "top" when the user is at the far right of the graph
      if (scrollOffset === 0) {
        onScrollChange?.("top")
      }
    },
    [
      setScrollPosition,
      onScrollChange,
    ],
  )

  const ref = useMergeRefs<HTMLDivElement>([
    containerElementRef,
    containerRef,
    refs.setReference,
  ])

  return (
    <div
      className={styles.container}
      ref={ref}
      data-icon-scale={graphIconScale}
      {...getReferenceProps}
      id={graphSelectorId}
    >
      <Overlay />
      <AutoSizer>
        {({
          height,
          width,
        }: ReactWindowAutoSizerArgs) => (
          <VariableSizeList
            height={height}
            width={width}
            ref={listElementRef}
            itemCount={numberOfItems}
            estimatedItemSize={cellWidth}
            layout="horizontal"
            direction="rtl"
            overscanCount={1}
            itemSize={getItemSize}
            itemKey={getItemKey}
            onItemsRendered={updateVisibleIndices}
            onScroll={onScroll}
          >
            {Cell}
          </VariableSizeList>
        )}
      </AutoSizer>
    </div>
  )
}
