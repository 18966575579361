import {
  Slider,
  SliderInput,
  SliderTracks,
  SliderValue,
  SliderValueInput,
  SliderValueLabel,
  SliderValueLabelContainer,
} from "@tandemdiabetes/sugarmate-react-components"
import type { ChangeEventHandler } from "react"
import { useCallback } from "react"
import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"
import type {
  SubEntryType,
  EditableSubEntry,
} from "src/types"
import { round } from "src/utils"

interface Props<T extends SubEntryType> {
  currentSubEntry: EditableSubEntry<T>;
  updateSubEntry: (s: Partial<EditableSubEntry<T>>) => void;
  min?: number;
  max?: number;
  step?: number;
}

const id = "subentry-duration-input"

export function DurationInput<T extends SubEntryType>(props: Props<T>) {
  const {
    currentSubEntry,
    updateSubEntry,
    min = 0,
    max = 300,
    step = 5,
  } = props

  const value = round((currentSubEntry.duration_in_seconds ?? 0) / 60)
  const unset = value <= min

  const polyglot = usePolyglot()

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const durationInMinutes = Number(e.target.value)

      updateSubEntry({
        duration_in_seconds: durationInMinutes * 60,
      })
    },
    [updateSubEntry],
  )

  return (
    <div className={styles.row}>
      <SliderValue sizePreset="large">
        <SliderValueInput
          max={max}
          min={min}
          id={id}
          onChange={onChange}
          value={unset ? "" : value}
        />
        <SliderValueLabelContainer>
          <SliderValueLabel htmlFor={id}>
            {polyglot.t(
              "components.entry_modal.duration.label_detail",
              value,
            )}
          </SliderValueLabel>
        </SliderValueLabelContainer>
      </SliderValue>
      <Slider className={styles.rangeInputSlider}>
        <SliderInput
          min={min}
          max={max}
          step={step}
          id={id}
          value={value}
          onChange={onChange}
        />
        <SliderTracks
          min={min}
          max={max}
        />
      </Slider>
    </div>
  )
}
