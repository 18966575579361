import {
  ActionButton,
  ActionSubmit,
  ModalBody,
  ModalButtons,
  ModalHeading,
  ModalCloseX,
} from "@tandemdiabetes/sugarmate-react-components"
import { ContextMenu } from "./ContextMenu"
import { DateTimeInput } from "./DateTimeInput"

import { useEntryModalPrivateContext } from "./EntryModalPrivateContext"
import { useEntryModalPublicContext } from "./EntryModalPublicContext"
import { EntryModalSubEntrySummary } from "./EntryModalSubEntrySummary"
import styles from "./EntrySummary.module.scss"
import { usePolyglot } from "src/contexts"
import { useEntryTemplateTitle } from "src/hooks"
import type {
  SubEntryType,
  ReadOnlySubEntry,
  ReadOnlyUserEntry,
  EditableUserEntry,
} from "src/types"

const polyglotPrefix = "components.entry_modal."

interface Props {
  currentUserEntry: ReadOnlyUserEntry;
  setCurrentUserEntry: (userEntry: EditableUserEntry) => void;
}

export function EntrySummary(props: Props) {
  const {
    currentUserEntry,
    setCurrentUserEntry,
  } = props

  const polyglot = usePolyglot()
  const {
    addSubEntry,
    showSubEntry,
  } = useEntryModalPrivateContext()

  const { hasUnsavedChanges } = useEntryModalPublicContext()
  const entryTemplateTitle = useEntryTemplateTitle(currentUserEntry.entry_template_id ?? "")

  return (
    <>
      <ModalHeading>
        <div className={styles.headingContent}>
          {currentUserEntry.entry_template_id
            ? entryTemplateTitle
            : polyglot.t(`${polyglotPrefix}summary_title`)
          }
          <DateTimeInput
            currentUserEntry={currentUserEntry}
            setCurrentUserEntry={setCurrentUserEntry}
          />
        </div>
        <ContextMenu currentUserEntry={currentUserEntry} />
        <ModalCloseX id={"close-icon"}/>
      </ModalHeading>
      <ModalBody className={styles.modalBody}>
        <div className={styles.activities}>
          {currentUserEntry.subentries.map(
            (subEntry: ReadOnlySubEntry, index: number) => (
              <EntryModalSubEntrySummary
                activityType={subEntry.subentry_type}
                activity={subEntry[subEntry.subentry_type as (SubEntryType & keyof typeof subEntry)]}
                item={subEntry}
                key={subEntry._id || `${subEntry.subentry_type}-${index}`}
                onClick={() => showSubEntry(subEntry)}
              />
            ),
          )}
        </div>
        <ModalButtons>
          <ActionButton
            stylePreset="secondary"
            className={styles.modalMultiButton}
            onClick={addSubEntry}
            id={"add-another-item-button"}
          >
            {polyglot.t(`${polyglotPrefix}add_item_cta`)}
          </ActionButton>
          <ActionSubmit
            className={styles.modalMultiButton}
            disabled={!hasUnsavedChanges}
            value={polyglot.t(`${polyglotPrefix}save_cta`)}
          />
        </ModalButtons>
      </ModalBody>
    </>
  )
}
