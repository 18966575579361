import classNames from "classnames"
import type {
  ForwardedRef,
  HTMLAttributes,
} from "react"
import { forwardRef } from "react"

import { DayMetricContextProvider } from "./DayMetricContext"
import styles from "./Feed.module.scss"
import { FeedHeader } from "./FeedHeader"
import { ItemContextProvider } from "./ItemContext"
import { ReferencesContextProvider } from "./ReferencesContext"
import { ScrollPositionContextProvider } from "./ScrollPositionContext"
import { VirtualList } from "./VirtualList"
import type { Props as VirtualListProps } from "./VirtualList"

interface Props extends HTMLAttributes<HTMLDivElement>, VirtualListProps {
  feedStop: string;
  updateScrollController: () => void;
}

/*
 * this component renders the activity list within all of its necessary contexts
 */
export const Feed = forwardRef<HTMLDivElement, Props>(
  function Feed(props: Props, propRef: ForwardedRef<HTMLDivElement>) {
    const className = classNames(
      props.className,
      styles.container,
    )

    const {
      scrollToId,
      onScrollChange,
      feedStop,
      updateScrollController,
      ...divProps
    } = props

    return (
      <DayMetricContextProvider>
        <ReferencesContextProvider>
          <ScrollPositionContextProvider>
            <ItemContextProvider feedStop={feedStop}>
              <div
                {...divProps}
                ref={propRef}
                className={className}
                onFocus={updateScrollController}
                onTouchStart={updateScrollController}
                onMouseOver={updateScrollController}
                id="feed-content"
              >
                <FeedHeader />
                <VirtualList
                  onScrollChange={onScrollChange}
                  scrollToId={scrollToId}
                />
              </div>
            </ItemContextProvider>
          </ScrollPositionContextProvider>
        </ReferencesContextProvider>
      </DayMetricContextProvider>
    )
  },
)
