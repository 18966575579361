import {
  FieldSet,
  Legend,
  SelectionButton,
  SelectionButtons,
} from "@tandemdiabetes/sugarmate-react-components"
import { useCallback } from "react"
import type { ChangeEventHandler } from "react"

import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"
import type { ExerciseActivity } from "src/types"
import { ExerciseIntensity } from "src/types"

const polyglotPrefix = "components.entry_modal.exercise.intensity."

interface Props {
  activity: ExerciseActivity;
  updateActivity: (a: ExerciseActivity) => void;
}

export function ExerciseIntensityRadioButton(props: Props) {
  const {
    activity,
    updateActivity,
  } = props

  const polyglot = usePolyglot()

  const onExerciseIntensityChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const newValue = e.target.value as ExerciseIntensity

      updateActivity({
        ...activity,
        intensity: newValue,
      })
    },
    [
      updateActivity,
      activity,
    ],
  )

  return (
    <FieldSet>
      <Legend className={styles.legend}
        sizePreset="small"
      >
        {polyglot.t(`${polyglotPrefix}legend`)}
      </Legend>
      <SelectionButtons>
        <SelectionButton
          htmlFor={ExerciseIntensity.High}
          value={ExerciseIntensity.High}
          onChange={onExerciseIntensityChange}
          name="exercise_intensity"
          checked={activity.intensity === ExerciseIntensity.High}
          id={"intensity-select-buttons"}
        >
          {polyglot.t(`${polyglotPrefix}high`)}
        </SelectionButton>
        <SelectionButton
          htmlFor={ExerciseIntensity.Medium}
          value={ExerciseIntensity.Medium}
          onChange={onExerciseIntensityChange}
          name="exercise_intensity"
          checked={activity.intensity === ExerciseIntensity.Medium}
        >
          {polyglot.t(`${polyglotPrefix}medium`)}
        </SelectionButton>
        <SelectionButton
          htmlFor={ExerciseIntensity.Low}
          value={ExerciseIntensity.Low}
          onChange={onExerciseIntensityChange}
          name="exercise_intensity"
          checked={activity.intensity === ExerciseIntensity.Low}
        >
          {polyglot.t(`${polyglotPrefix}low`)}
        </SelectionButton>
      </SelectionButtons>
    </FieldSet>
  )
}
