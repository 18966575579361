import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
} from "@floating-ui/react"
import type {
  ComponentPropsWithoutRef,
  CSSProperties,
} from "react"
import { useMemo } from "react"

import { CreateButton } from "./CreateButton"
import styles from "./CreationOverlay.module.scss"
import { FavoriteButton } from "./FavoriteButton"
import { SubEntryButton } from "./SubEntryButton"
import { useCreationOverlayContext } from "./useCreationOverlayContext"
import { useDeviceSettingsProperty } from "src/hooks"
import { enforceFeedButtons } from "src/utils"

export function CreationOverlayContent() {
  const state = useCreationOverlayContext()
  const userFeedButtons = useDeviceSettingsProperty("feed_buttons")

  const feedButtons = useMemo(
    () => enforceFeedButtons(userFeedButtons),
    [userFeedButtons],
  )

  const floatingProps: ComponentPropsWithoutRef<"div"> = state.getFloatingProps()

  const style: CSSProperties = useMemo(
    () => ({
      transitionDuration: `${state.transitionMs}ms`,
    }),
    [state.transitionMs],
  )

  return (
    <FloatingPortal>
      {state.isMounted ? (
        <FloatingOverlay
          className={styles.overlay}
          data-status={state.status}
          style={style}
          lockScroll
        >
          <FloatingFocusManager
            context={state.context}
            initialFocus={-1}
          >
            <>
              <div
                aria-labelledby={state.labelId}
                aria-describedby={state.descriptionId}
                ref={state.refs.setFloating}
                {...floatingProps}
              >
                <div
                  className={styles.buttons}
                  data-status={state.status}
                  style={style}
                  id="subentry-container"
                >
                  {feedButtons.map(
                    (feedButton) => {
                      // TODO we don't support search yet
                      if (feedButton === "search") {
                        return null
                      }

                      if (feedButton === "favorite") {
                        return (
                          <FavoriteButton
                            key={feedButton}
                          />
                        )
                      }

                      return (
                        <SubEntryButton
                          key={feedButton}
                          activityType={feedButton}
                        />
                      )
                    },
                  )}
                </div>
                <CreateButton />
              </div>
            </>
          </FloatingFocusManager>
        </FloatingOverlay>
      ) : (
        <CreateButton />
      )
      }
      <CreateButton />
    </FloatingPortal>
  )
}
