import {
  ActionButton,
  ActionSubmit,
  Dialog,
  DialogButton,
  DialogButtons,
  DialogContent,
} from "@tandemdiabetes/sugarmate-react-components"
import type {
  FunctionComponent,
  MouseEventHandler,
} from "react"
import {
  useCallback,
  useState,
} from "react"

import { useEntryModalPrivateContext } from "./EntryModalPrivateContext"
import { useEntryModalPublicContext } from "./EntryModalPublicContext"
import { usePolyglot } from "src/contexts"
import styles from "src/styles/entryModal.module.scss"
import type {
  SubEntryType,
  EditableSubEntry,
  EditableUserEntry,
} from "src/types"

const polyglotPrefix = "components.entry_modal."

interface Props {
  currentUserEntry: EditableUserEntry;
  currentSubEntry: EditableSubEntry<SubEntryType>;
  // a dialog heading and / or body that will be shown as a warning to the user
  SaveWarning?: FunctionComponent;
}

export function SubEntryFormButtons(props: Props) {
  const {
    currentUserEntry,
    currentSubEntry,
    SaveWarning,
  } = props

  const polyglot = usePolyglot()

  const {
    addSubEntry,
    showEntry,
    formId,
  } = useEntryModalPrivateContext()

  const { hasUnsavedChanges } = useEntryModalPublicContext()

  const [
    warningDialogOpen,
    setWarningDialogOpen,
  ] = useState<boolean>(false)

  const openWarningDialog: MouseEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      e.preventDefault()
      setWarningDialogOpen(true)
    },
    [setWarningDialogOpen],
  )

  const closeSubEntry: MouseEventHandler<HTMLButtonElement | HTMLInputElement> = useCallback(
    (e) => {
      e.preventDefault()
      showEntry()
    },
    [showEntry],
  )

  const closeDialog: MouseEventHandler<HTMLInputElement> = useCallback(
    () => {
      setWarningDialogOpen(false)
    },
    [setWarningDialogOpen],
  )

  const addItem: MouseEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      e.preventDefault()
      addSubEntry()
    },
    [addSubEntry],
  )

  let numberOfSubEntries = currentUserEntry.subentries.length
  if (!currentSubEntry._id) {
    numberOfSubEntries += 1
  }

  return (
    <Dialog
      open={warningDialogOpen}
      onOpenChange={setWarningDialogOpen}
    >
      <DialogButtons>
        <ActionSubmit
          stylePreset="secondary"
          className={styles.dialogMultiButton}
          onClick={addItem}
          value={polyglot.t(`${polyglotPrefix}add_item_cta`)}
          id="add-another-item-button"
        />
        {numberOfSubEntries > 1 ? (
          // this is ActionSubmit instead of button so that it
          // is disabled / enabled based on form validation
          <ActionSubmit
            className={styles.dialogMultiButton}
            onClick={SaveWarning ? openWarningDialog : closeSubEntry}
            value={polyglot.t(`${polyglotPrefix}done_cta`)}
            id={"sub-entry-done-button"}
          />
        ) : (
          <ActionSubmit
            onClick={SaveWarning ? openWarningDialog : undefined}
            className={styles.dialogMultiButton}
            disabled={!hasUnsavedChanges}
            value={polyglot.t(`${polyglotPrefix}save_cta`)}
            id={"sub-entry-save-button"}
          />
        )}
      </DialogButtons>
      <DialogContent>
        {SaveWarning && <SaveWarning />}
        <DialogButtons>
          <DialogButton stylePreset="secondary">
            {polyglot.t(`${polyglotPrefix}cancel_cta`)}
          </DialogButton>
          {numberOfSubEntries > 1 ? (
            <ActionButton
              className={styles.dialogMultiButton}
              onClick={closeSubEntry}
            >
              {polyglot.t(`${polyglotPrefix}done_cta`)}
            </ActionButton>
          ) : (
            <ActionSubmit
              className={styles.dialogMultiButton}
              value={polyglot.t(`${polyglotPrefix}save_cta`)}
              onClick={closeDialog}
              form={formId}
            />
          )}
        </DialogButtons>
      </DialogContent>
    </Dialog>
  )
}
